/* ---------------------------------------------------- */
/* ----- TOGGLE MENU----- */
/* ---------------------------------------------------- */

  const btn = document.querySelector('#btnToggle');
  btn.addEventListener('click', function(e) {
    e.preventDefault();
    document.body.classList.toggle('menu--show');
    this.classList.toggle('btn-toggle--toggled');
  });


  /* ---------------------------------------------------- */
  /* ----- TOGGLE COMMENTS FORM ----- */
  /* ---------------------------------------------------- */

  const formToggle = document.querySelector('.post__comments-add');
  formToggle.addEventListener('click', function(e) {
    e.preventDefault();
    document.querySelector('.post__comments-form').classList.toggle('post__comments-form--open');
  });


  /* ---------------------------------------------------- */
  /* ----- AUTOMATICALLY EXPAND A TEXTAREA ----- */
  /* ---------------------------------------------------- */

  let autoExpand = function (field) {

    // Reset field height
    field.style.height = 'inherit';

    // Get the computed styles for the element
    let computed = window.getComputedStyle(field);

    // Calculate the height
    let height = parseInt(computed.getPropertyValue('border-top-width'), 10)
        + parseInt(computed.getPropertyValue('padding-top'), 10)
        + field.scrollHeight
        + parseInt(computed.getPropertyValue('padding-bottom'), 10)
        + parseInt(computed.getPropertyValue('border-bottom-width'), 10);

    field.style.height = height + 'px';

  };

  document.addEventListener('input', function (event) {
    if (event.target.tagName.toLowerCase() !== 'textarea') return;
    autoExpand(event.target);
  }, false);


  /* ---------------------------------------------------- */
  /* ----- HIGHLIGHT CURRENT PAGE ACTIVE MENU ITEM  ----- */
  /* ---------------------------------------------------- */

  const selector = '.nav__link';
  const elems = Array.from( document.querySelectorAll(selector));
  const navigation = document.querySelector('nav');

  function makeActive(evt) {
    const target = evt.target;

    if ( !target || !target.matches(selector)) {
      return;
    }

    elems.forEach(elem => elem.classList.remove('active'));
    evt.target.classList.add('active');
  };

  navigation.addEventListener('mousedown', makeActive);